import {
    FormControl,
    FormLabel,
    Switch,
} from "@chakra-ui/react";
import {FastField, FieldProps} from "formik";
import * as React from "react";
import _ from "lodash";

export interface FormFieldBooleanProps {
    fieldId: string
    fieldTitle: string
}

export const FormFieldBoolean = (props: FormFieldBooleanProps) => {
    const {fieldId, fieldTitle} = props
    return (
        <FastField name={fieldId}>
            {({ field, form }: FieldProps) => (
                <FormControl display="flex" alignItems="center">
                    <FormLabel htmlFor={fieldId} mb="0">
                        {fieldTitle}
                    </FormLabel>
                    <Switch isChecked={_.get(form.values, fieldId)} {...field} id={fieldId} />
                </FormControl>
            )}
        </FastField>
    )
}