import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Image, VStack, Flex, Center, Link, Spinner
} from "@chakra-ui/react";
import {Field, FieldProps, useFormikContext} from "formik";
import * as React from "react";
import _ from "lodash";
import {useDropzone} from "react-dropzone";
import {useCallback, useState} from "react";
import {FiImage} from "react-icons/all";
import {FiUpload} from "react-icons/fi";

export interface ImageUploadResult {
    imageUrl: string
}

export interface ImageFileUploadService {
    uploadImageFile(file: File): Promise<ImageUploadResult>
}

interface FormFieldImageProps {
    fieldId: string
    fieldTitle: string
    validatorFactory?: (n: string) => (n: string) => string | undefined
    imageUploadService: ImageFileUploadService
}

export const FormFieldImage = (props: FormFieldImageProps) => {
    const {fieldId, fieldTitle, validatorFactory, imageUploadService} = props
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [imageHoverButtonIsVisible, setImageHoverButtonIsVisible] = useState(false)
    const { setFieldValue } = useFormikContext();


    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        if (file == null) {
            return
        }
        setIsUploading(true)
        try {
            const result = await imageUploadService.uploadImageFile(file)
            setFieldValue(fieldId, result.imageUrl)
        } catch (error) {
            console.error(error)
        } finally {
            setIsUploading(false)
        }
    }, [imageUploadService, fieldId, setFieldValue])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        onDrop,
        accept: "image/jpeg, image/png"
    })

    return (
        <Field name={fieldId} validate={validatorFactory ? validatorFactory(fieldTitle) : undefined}>
            {({ field, form }: FieldProps) => {
                const isValid = _.get(form.errors, fieldId) == null
                return (
                <FormControl isInvalid={_.get(form.errors, fieldId) != null && _.get(form.touched, fieldId) as boolean}>
                    <FormLabel htmlFor={fieldId}>{fieldTitle}</FormLabel>
                    <VStack align={"start"} spacing={3}>
                        <Input hidden={true} {...field} id={fieldId} placeholder={fieldTitle} />

                        <Flex title={"Drop an image file here or click to select a file"} {...getRootProps()}  position={"relative"}
                              borderStyle={"solid"} borderRadius={5} borderWidth={isValid ? "1px": "2px"} borderColor={isValid ? "gray.200" : "#E53E3E"}
                              cursor={"pointer"}
                              onMouseEnter={() => {
                                  setImageHoverButtonIsVisible(true)
                              }}
                              onMouseLeave={() => {
                                  setImageHoverButtonIsVisible(false)
                              }}
                        >
                            <Flex
                                zIndex={3}
                                position={"absolute"}
                                opacity={imageHoverButtonIsVisible || isDragActive ? 0.5 : 0}
                                backgroundColor={"blue.200"} top={0} bottom={0} left={0} right={0}
                            >
                            </Flex>

                            <input {...getInputProps()} />

                            <Flex
                                  zIndex={3}
                                  position={"absolute"}
                                  opacity={imageHoverButtonIsVisible || isDragActive ? 0.9 : 0}
                                  top={0} bottom={0} left={0} right={0}
                            >
                                <Center width={"100%"}>
                                    {isDragActive  ?
                                        <FiUpload opacity={1} color={"white"} size={"60px"}/> :
                                        <FiImage opacity={1} color={"white"} size={"60px"}/>
                                    }

                                </Center>
                            </Flex>

                            {isUploading &&
                            <Flex
                                zIndex={4}
                                position={"absolute"}
                                opacity={isUploading ? 1 : 0}
                                top={0} bottom={0} left={0} right={0}
                            >
                                <Center width={"100%"}>
                                        <Spinner size={"xl"} thickness={"4px"}/>
                                </Center>
                            </Flex>
                            }
                            <Image zIndex={2}  align={"center"} boxSize={"300px"} fit={"scale-down"} src={field.value} />
                        </Flex>
                        <FormErrorMessage>{_.get(form.errors, fieldId)}</FormErrorMessage>
                        <Link href={field.value}>
                            {field.value}
                        </Link>
                    </VStack>
                </FormControl>
            )}}
        </Field>
    )
}