export function createRequiredValidator(name: string) {
    return (value: string) => {
        let error
        if (!value) {
            error = `${name} is required`
        }
        return error
    }
}

export function createGreaterThanZeroValidator(name: string) {
    return (value: number) => {
        let error
        if (!(value > 0)) {
            error = `${name} should be greater than zero`
        }
        return error
    }
}