import React from "react";

export interface Language {
    code: string,
    crowdinEditorCode: string,
    name: string
}

export const defaultLanguage: Language = {code: "en", name: "English", crowdinEditorCode: "en"}

export class LocalizationState {
    language: Language
    crowdinProjectCode: string
    crowdinFileId?: number | null

    constructor(language: Language, crowdinProjectCode: string, crowdinFileId?: number | null) {
        this.language = language
        this.crowdinProjectCode = crowdinProjectCode
        this.crowdinFileId = crowdinFileId
    }
    isBaseLanguage(): boolean {
        return this.language.code === defaultLanguage.code
    }
}

const LocalizationContext = React.createContext<LocalizationState | null>(null)

interface LocalizationProviderProps {
    children: React.ReactNode
    localizationState: LocalizationState
}

export const LocalizationProvider = (props: LocalizationProviderProps) => {
    return (
        <LocalizationContext.Provider value={props.localizationState}>
            {props.children}
        </LocalizationContext.Provider>
    )
}

export const useLocalizationContext = () => React.useContext(LocalizationContext)