import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Select
} from "@chakra-ui/react";
import {FastField, FieldProps} from "formik";
import * as React from "react";
import _ from "lodash";

interface FormFieldSelectOption {
    value: string
    title: string
}

interface FormFieldSelectProps {
    fieldId: string
    fieldTitle: string
    options: FormFieldSelectOption[]
    validatorFactory?: (n: string) => (n: string) => string | undefined
}

export const FormFieldSelect = (props: FormFieldSelectProps) => {
    const {fieldId, fieldTitle, validatorFactory, options} = props
    return (
        <FastField name={fieldId} validate={validatorFactory ? validatorFactory(fieldTitle) : undefined}>
            {({ field, form }: FieldProps) => (
                <FormControl isInvalid={_.get(form.errors, fieldId) != null && _.get(form.touched, fieldId) as boolean}>
                    <FormLabel htmlFor={fieldId}>{fieldTitle}</FormLabel>
                    <Select {...field} id={fieldId} placeholder={`Select ${fieldTitle}`}>
                        {options.map((option, i)=>{
                            return (
                                <option key={i} value={option.value}>{option.title}</option>
                            )
                        })}
                    </Select>
                    <FormErrorMessage>{_.get(form.errors, fieldId)}</FormErrorMessage>
                </FormControl>
            )}
        </FastField>
    )
}