import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper,
} from "@chakra-ui/react";
import {FastField, FieldProps} from "formik";
import * as React from "react";
import _ from "lodash";

interface FormFieldIntProps {
    fieldId: string
    fieldTitle: string
    validatorFactory: (n: string) => (v: number) => string | undefined
    min?: number | undefined
    max?: number | undefined
    defaultValue?: number | undefined
}

export const FormFieldInt = (props: FormFieldIntProps) => {
    const {fieldId, fieldTitle, validatorFactory, min, max, defaultValue} = props
    return (
        <FastField name={fieldId} validate={validatorFactory ? validatorFactory(fieldTitle) : undefined}>
            {({ field, form }: FieldProps) => (
                <FormControl isInvalid={_.get(form.errors, fieldId) != null && _.get(form.touched, fieldId) as boolean}>
                    <FormLabel htmlFor={fieldId}>{fieldTitle}</FormLabel>
                    <NumberInput {...field} onChange={(_v, n) => {
                        form.setFieldValue(fieldId, n);
                    }} id={fieldId} min={min} max={max} defaultValue={defaultValue} precision={0}>
                        <NumberInputField/>
                        <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                        </NumberInputStepper>
                    </NumberInput>

                    <FormErrorMessage>{_.get(form.errors, fieldId)}</FormErrorMessage>
                </FormControl>
            )}
        </FastField>
    )
}