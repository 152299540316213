
import * as React from "react"
import {
  Box,
  Flex,
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react"
import {FiChevronRight} from "react-icons/all";
import { Link as RouterLink} from "react-router-dom";

export interface PathItem {
    name: string, 
    path: string
}

export interface IBreadcrumbProps {
    pathItems: PathItem[]
}

export const Breadcrumb = (props: IBreadcrumbProps) => {
    return (
        <Flex py="2" borderBottomStyle="solid" borderBottomWidth="2px" borderBottomColor="gray.100">
            <Box px="4">
                <ChakraBreadcrumb separator={<FiChevronRight color="gray.500" />}>
                    {props.pathItems.map((item: PathItem, i) => (
                        <BreadcrumbItem key={i} isCurrentPage={i === props.pathItems.length - 1}>
                            <BreadcrumbLink as={RouterLink} to={item.path}>{item.name}</BreadcrumbLink>
                        </BreadcrumbItem>    
                    ))}
                </ChakraBreadcrumb>
            </Box>
        </Flex>
    )
}