import {chakra, PropsOf} from "@chakra-ui/react"
import {Link as RouterLink} from "react-router-dom";

import React from "react"

const StyledLink = React.forwardRef(function StyledLink(
    props: PropsOf<typeof chakra.a> & { isActive?: boolean },
    ref: React.Ref<any>,
) {
    const {isActive, ...rest} = props

    return (
        <chakra.a
            aria-current={isActive ? "page" : undefined}
            width="100%"
            px="3"
            py="1"
            rounded="md"
            ref={ref}
            fontSize="sm"
            fontWeight="500"
            color="gray.700"
            transition="all 0.2s"
            _activeLink={{
                bg: "blue.50",
                color: "blue.700",
                fontWeight: "600",
            }}
            {...rest}
        />
    )
})

type SidebarLinkProps = PropsOf<typeof chakra.div> & {
    href: string
    icon?: React.ReactElement
    isActive: boolean
}

export const SidebarLink = (props: SidebarLinkProps) => {
    const {href, isActive, icon, children, ...rest} = props

    return (
        <chakra.div
            userSelect="none"
            display="flex"
            alignItems="center"
            lineHeight="1.5rem"
            {...rest}
        >

            <StyledLink as={RouterLink} to={href} isActive={isActive}>{children}</StyledLink>

        </chakra.div>
    )
}