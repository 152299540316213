import {
    FormControl,
    FormErrorMessage,
    FormLabel, Link,
    Textarea
} from "@chakra-ui/react";
import {Field, FieldProps} from "formik";
import * as React from "react";
import _ from "lodash";
import {useLocalizationContext} from "./LocalizationContext";
import {crowdinLocalizableStringLink, getLocalizedFieldValue} from "./Utils/LocalizationUtils";
import {useMemo} from "react";

interface FormFieldTextAreaProps {
    fieldId: string
    fieldTitle: string
    minHeight: string
    isLocalized?: boolean
    l10nStringKeyFieldName?: string
    validatorFactory?: (n: string) => (n: string) => string | undefined
}

type FieldBodyProps = FieldProps & FormFieldTextAreaProps

const FieldBody = ({field, fieldId, form, fieldTitle,minHeight, isLocalized = false, l10nStringKeyFieldName}: FieldBodyProps) => {
    const l10nState = useLocalizationContext()
    const isLocalizedVersion = (l10nState != null) && isLocalized && !l10nState.isBaseLanguage()
    const fieldValue = (() => {
        if (isLocalizedVersion && l10nState != null) {
            return getLocalizedFieldValue(form.values, fieldId, l10nState.language.code) ?? ""
        } else {
            return _.get(form.values, fieldId)
        }
    })()
    const isTouched = _.get(form.touched, field.name) as boolean
    const errors = _.get(form.errors, field.name)
    return useMemo ( () => (
        <FormControl isInvalid={errors != null && isTouched}>
            <FormLabel htmlFor={fieldId}>{fieldTitle}
                {isLocalizedVersion &&
                    <>
                        &nbsp;-&nbsp;
                        <Link color={"green.500"} isExternal href={crowdinLocalizableStringLink(l10nState?.crowdinProjectCode ?? "",
                            l10nState?.crowdinFileId ?? 0,
                            l10nState?.language.crowdinEditorCode ?? "",
                            l10nStringKeyFieldName ?? "")}>
                            {l10nState?.language.name} translation
                        </Link>
                    </>

                }
            </FormLabel>
            <Textarea readOnly={isLocalizedVersion} minH={minHeight} {...field} value={fieldValue} id={fieldId}
                      backgroundColor={isLocalizedVersion ? "green.50" : undefined}
                      placeholder={isLocalizedVersion ? `No ${l10nState?.language.name} translation` : fieldTitle} />
            <FormErrorMessage>{errors}</FormErrorMessage>
        </FormControl>
    ), [fieldValue, fieldId, l10nStringKeyFieldName, minHeight, errors, isTouched, field, fieldTitle, isLocalizedVersion, l10nState])
}

export const FormFieldTextArea = (props: FormFieldTextAreaProps) => {
    const {fieldId, fieldTitle, validatorFactory} = props
    return (
        <Field name={fieldId} validate={validatorFactory ? validatorFactory(fieldTitle) : undefined}>
            {(fieldProps: FieldProps) => (
                <FieldBody {...fieldProps} {...props}/>
            )}
        </Field>
    )
}