import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    NumberInput, NumberInputField,
} from "@chakra-ui/react";
import {Field, FieldProps, useFormikContext} from "formik";
import * as React from "react";
import _ from "lodash";
import {useState} from "react";

interface FormFieldFloatProps {
    fieldId: string
    fieldTitle: string
    precision?: number | undefined
    min?: number | undefined
    max?: number | undefined
    defaultValue?: number | undefined
}

export const FormFieldFloat = (props: FormFieldFloatProps) => {
    const {fieldId, fieldTitle,min, max, precision, defaultValue} = props
    const {initialValues} = useFormikContext()
    const [inputValue, setInputValue] = useState(_.get(initialValues, fieldId))

    return (
        <Field should name={fieldId} >
            {({ field, form }: FieldProps) => (
                <FormControl isInvalid={_.get(form.errors, fieldId) != null && _.get(form.touched, fieldId) as boolean}>
                    <FormLabel htmlFor={fieldId}>{fieldTitle}</FormLabel>
                    <NumberInput value={inputValue} precision={precision} defaultValue={defaultValue} min={min} max={max} id={fieldId} onChange={(v, n) => {
                        setInputValue(v);
                        if (field.value === n) {
                            form.setFieldValue(fieldId, "TempValueToTriggerRerender")
                        }
                        form.setFieldValue(fieldId, n)
                    }} >
                        <NumberInputField/>
                    </NumberInput>
                    <FormErrorMessage>{_.get(form.errors, fieldId)}</FormErrorMessage>
                </FormControl>
            )}
        </Field>
    )
}