import {
    Box, Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Input, Link,
    Spacer,
    VStack
} from "@chakra-ui/react";
import {Field, FieldArray, FieldArrayRenderProps, FieldProps, FormikTouched} from "formik";
import {FiArrowDown, FiArrowUp, FiPlus, FiTrash} from "react-icons/fi";
import * as React from "react";
import {createRequiredValidator} from "./validator/Validators";
import _ from "lodash";
import {useLocalizationContext} from "./LocalizationContext";
import {crowdinLocalizableStringLink, getLocalizedFieldValue} from "./Utils/LocalizationUtils";
import {useMemo} from "react";

interface FormFieldStringListProps {
    fieldId: string
    fieldTitle: string
    isLocalized?: boolean
    l10nStringKeyFieldName?: string
    fieldTitleSingular: string
}

type FieldBodyProps = FieldArrayRenderProps & FormFieldStringListProps & {isValid: boolean}

const FieldBody = ({
                       fieldId,
                       fieldTitleSingular,
                       form,
                       fieldTitle,
                       isLocalized = false,
                       l10nStringKeyFieldName,
                       move,
                       remove,
                       push,
                       isValid
                   }: FieldBodyProps) => {
    const l10nState = useLocalizationContext()
    const isLocalizedVersion = (l10nState != null) && isLocalized && !l10nState.isBaseLanguage()
    const errors = _.get(form.errors, fieldId)
    const touched = _.get(form.touched, fieldId)
    const fieldValue = _.get(form.values, fieldId)

    return useMemo ( () => {
        const localizedValue: string[] | null = (isLocalizedVersion && l10nState != null) ?
            (getLocalizedFieldValue(form.values, fieldId, l10nState.language.code) as string[] ?? []) : null
        return (
            <FormControl isInvalid={!isValid}>
                <FormLabel htmlFor={fieldId}>{fieldTitle}
                    {isLocalizedVersion &&
                    <>
                        &nbsp;-&nbsp;
                        <Link color={"green.500"} isExternal
                              href={crowdinLocalizableStringLink(l10nState?.crowdinProjectCode ?? "",l10nState?.crowdinFileId ?? 0, l10nState?.language.crowdinEditorCode ?? "", l10nStringKeyFieldName ?? "")}>
                                {l10nState?.language.name} translation
                        </Link>
                    </>
                    }
                </FormLabel>
                <VStack mt={0} spacing={4} alignItems={"start"} px={2} pt={4} pb={4} width={"100%"}
                        borderColor={isValid ? "#E3E8EF" : "#E53E3E"} borderRadius={"md"}
                        borderWidth={isValid ? "1px" : "2px"} borderStyle={"solid"}>
                    {fieldValue.map((value: string, i: number) => {
                        const error = (errors as string[])?.[i];
                        const itemTouched = (touched as unknown as FormikTouched<boolean>[])?.[i]
                        const itemValue: string = (isLocalizedVersion)
                            ? (localizedValue && i < localizedValue.length ? localizedValue[i] : "")
                            : value
                        return (
                            <Box key={i} width={"100%"} px={2} alignItems={"start"}>
                                <HStack mt="2">
                                    <Field name={`${fieldId}.${i}`}
                                           validate={createRequiredValidator(fieldTitleSingular)}>
                                        {({field}: FieldProps) => (
                                            <FormControl isInvalid={error != null && itemTouched}>
                                                <Input readOnly={isLocalizedVersion} {...field} value={itemValue}
                                                       id={`${fieldId}.${i}.title`}
                                                       backgroundColor={isLocalizedVersion ? "green.50" : undefined}
                                                       placeholder={isLocalizedVersion ? `No ${l10nState?.language.name} translation` : fieldTitleSingular}/>
                                                <FormErrorMessage>{error}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Spacer/>

                                    {i > 0 &&
                                    <IconButton disabled={isLocalizedVersion} borderRadius={"md"} aria-label="Move Up"
                                                onClick={() => {
                                                    move(i, i - 1)
                                                }}
                                                icon={<FiArrowUp/>}/>
                                    }
                                    {i < fieldValue.length - 1 &&
                                    <IconButton disabled={isLocalizedVersion} borderRadius={"md"} aria-label="Move Down"
                                                onClick={() => {
                                                    move(i, i + 1)
                                                }}
                                                icon={<FiArrowDown/>}/>
                                    }
                                    <IconButton disabled={isLocalizedVersion} colorScheme={"red"}
                                                borderRadius={"md"}
                                                aria-label="Remove"
                                                icon={<FiTrash/>}
                                                onClick={() => {
                                                    remove(i)
                                                }}/>
                                </HStack>

                            </Box>
                        );
                    })}
                    <Box py={4}>
                        <Button disabled={isLocalizedVersion} ms={6} leftIcon={<FiPlus/>} onClick={() => {
                            push("")
                        }}>
                            Add {fieldTitleSingular}
                        </Button>
                    </Box>
                </VStack>
                <FormErrorMessage>{_.get(errors, `${fieldId}_Array`)}</FormErrorMessage>
            </FormControl>

        )
    }, [form.values, isValid, fieldId, fieldTitle, isLocalizedVersion, l10nState,
        l10nStringKeyFieldName, fieldValue, fieldTitleSingular, errors, touched, move, remove, push])
}

export const FormFieldStringList = (props: FormFieldStringListProps) => {
    const {fieldId} = props

    return (
            <FieldArray name={fieldId}>
                { (fieldProps: FieldArrayRenderProps) => {
                    const isValid = _.get(fieldProps.form.errors, `${fieldId}_Array`) == null
                    return  (
                        <FieldBody {...fieldProps} {...props} isValid={isValid}/>
                    )
                }}
            </FieldArray>
    )
}