
import * as React from "react"
import {SidebarItem} from "./SidebarItem"
import {SidebarLink} from "./SidebarLink"
import {
  Box,
  Stack,
} from "@chakra-ui/react"
import _ from "lodash"
import {matchPath} from "react-router-dom";
import {useLocation} from "react-router-dom";

export interface ISidebarProps {
  items: SidebarItem[]
}

export const Sidebar = ({ items }: ISidebarProps) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const location = useLocation()

  return (
    <Box
      ref={ref}
      as="nav"
      aria-label="Main Navigation"
      pos="sticky"
      sx={{
        overscrollBehavior: "contain",
      }}
      top="6.5rem"
      w="280px"
      h="calc(100vh - 8.125rem)"
      pr="8"
      pb="6"
      pl="6"
      pt="4"
      overflowY="auto"
      className="sidebar-content"
      flexShrink={0}
      borderRightColor="gray.100"
      borderRightStyle="solid"
      borderRightWidth="2px"
      display={{ base: "none", md: "block" }}
    >
      <Stack as="ul">
        {items.map((item: SidebarItem) =>  {
          const allPaths = _.concat(item.relatedPaths, [item.mainPath])
          const isActive = _.filter(allPaths, (p)=>(matchPath(location.pathname, {
            path: p,
            exact: false
          }))).length > 0
          return (
              <SidebarLink isActive={isActive} as="li" key={item.mainPath} href={item.mainPath}>
                <span>{item.title}</span>
              </SidebarLink>
          )
        })})
      </Stack>

    </Box>
  )
}