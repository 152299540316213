import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input, Link,
} from "@chakra-ui/react";
import { Field, FieldProps} from "formik";
import * as React from "react";
import _ from "lodash";
import {useLocalizationContext} from "./LocalizationContext";
import {useMemo} from "react";
import {crowdinLocalizableStringLink, getLocalizedFieldValue} from "./Utils/LocalizationUtils";

interface FormFieldTextProps {
    fieldId: string
    isLocalized?: boolean
    l10nStringKeyFieldName?: string
    fieldTitle: string
    validatorFactory?: (n: string) => (n: string) => string | Promise<string|undefined> | undefined
}

type FieldBodyProps = FieldProps & FormFieldTextProps

const FieldBody = ({field, fieldId, form, fieldTitle, isLocalized = false, l10nStringKeyFieldName}: FieldBodyProps) => {
    const l10nState = useLocalizationContext()
    const isLocalizedVersion = (l10nState != null) && isLocalized && !l10nState.isBaseLanguage()
    const fieldValue = (() => {
        if (isLocalizedVersion && l10nState != null) {
            return getLocalizedFieldValue(form.values, fieldId, l10nState.language.code) ?? ""
        } else {
            return _.get(form.values, fieldId)
        }
    })()

    const isTouched = _.get(form.touched, field.name) as boolean
    const errors = _.get(form.errors, field.name)

    return useMemo ( () => (
        <FormControl isInvalid={errors != null && isTouched}>
            <FormLabel
                htmlFor={field.name}>{fieldTitle}
                {isLocalizedVersion &&
                <>
                    &nbsp;-&nbsp;
                    <Link color={"green.500"} isExternal href={crowdinLocalizableStringLink(l10nState?.crowdinProjectCode ?? "",
                        l10nState?.crowdinFileId ?? 0,
                        l10nState?.language.crowdinEditorCode ?? "",
                        l10nStringKeyFieldName ?? "")}>
                        {l10nState?.language.name} translation
                    </Link>
                </>
                }
            </FormLabel>
            <Input readOnly={isLocalizedVersion} {...field} value={fieldValue} id={field.name}
                   backgroundColor={isLocalizedVersion ? "green.50" : undefined}
                   placeholder={isLocalizedVersion ? `No ${l10nState?.language.name} translation` : fieldTitle}/>
            <FormErrorMessage>{errors}</FormErrorMessage>
        </FormControl>), [fieldValue, isTouched, field, l10nStringKeyFieldName,  fieldTitle, isLocalizedVersion, l10nState, errors])
}

export const FormFieldText = (props: FormFieldTextProps) => {
    const { fieldTitle, validatorFactory} = props

    return (
    <Field name={props.fieldId} validate={validatorFactory ? validatorFactory(fieldTitle) : undefined}>
        {(fieldProps: FieldProps) => (
            <FieldBody {...fieldProps} {...props}/>
        )}
    </Field>)
}