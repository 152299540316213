
import * as React from "react"
import {
  Box,
  Flex,
  Button,
  Text,
  Spacer,
  Center
} from "@chakra-ui/react"
import { FiChevronsRight, FiChevronsLeft } from "react-icons/fi";

export interface IPaginatorProps {
    totalItemsCount: number,
    pageSize: number,
    currentPage: number,
    setCurrentPage: (page: number) => void
}

export const Paginator = (props: IPaginatorProps) => {
    const pageCount = Math.ceil(props.totalItemsCount / props.pageSize)

    const onPrevButtonClick = () => {
        if (props.currentPage > 0) {
            props.setCurrentPage(props.currentPage - 1)
        }
    }
    const onNextButtonClick = () => {
        if (props.currentPage < pageCount - 1) {
            props.setCurrentPage(props.currentPage + 1)
        }
    }

    return (
        <Flex>
            <Spacer />
            <Box p="4">
                <Button disabled={props.currentPage <= 0} colorScheme="blue" variant="outline" onClick={onPrevButtonClick}>
                    <FiChevronsLeft />
                </Button>
            </Box>
            <Center p="4">
                <Text align="center">{props.currentPage + 1} of {pageCount}</Text>
            </Center>
            <Box p="4">
                <Button disabled={props.currentPage >= pageCount - 1} colorScheme="blue" variant="outline" onClick={onNextButtonClick}>
                    <FiChevronsRight />
                </Button>
            </Box>
        </Flex>
    )
}