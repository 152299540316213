import {
    Box,
    FormControl,
    FormErrorMessage,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Spacer,
    VStack,
    Text, Center, Spinner,
} from "@chakra-ui/react";
import {FieldArray, FieldArrayRenderProps, Field, FieldProps, FormikTouched} from "formik";
import {FiArrowDown, FiArrowUp, FiTrash} from "react-icons/fi";
import * as React from "react";
import {createRequiredValidator} from "./validator/Validators";
import _ from "lodash";
import {useQuery} from "react-query";

import {CUIAutoComplete} from 'chakra-ui-autocomplete'

export type IdToTitleMap = {
    [key: string]: string
}

export type EntityInfo = {
    id: string
    title: string
}

export interface FormFieldEntityListProps {
    fieldName: string
    fieldTitle: string
    fieldTitleSingular: string
    fetchTitlesByIds: () => (() => Promise<IdToTitleMap>)
    fetchEntities: () => (() => Promise<EntityInfo[]>)
}

export const FormFieldEntityList = (props: FormFieldEntityListProps) => {
    const {fieldName, fieldTitle, fieldTitleSingular} = props

    const idToTitlesQuery = useQuery<IdToTitleMap, Error>(
        [`FormFieldEntityListQuery-${fieldName}`],
        props.fetchTitlesByIds(),
    )

    const searchQuery = useQuery<EntityInfo[], Error>(
        [`FormFieldEntitySearchQuery-${fieldName}`],
        props.fetchEntities(),
        {}
    )

    return (
        <>
            <FieldArray name={fieldName}>
                {(props: FieldArrayRenderProps) => {
                    const isValid = _.get(props.form.errors, `${fieldName}_Array`) == null

                    return (
                        <FormControl isInvalid={!isValid}>
                            <FormLabel htmlFor={fieldName}>{fieldTitle}</FormLabel>
                            <VStack mt={0} spacing={4} alignItems={"start"} px={2} pt={4} pb={4} width={"100%"}
                                    borderColor={isValid ? "#E3E8EF" : "#E53E3E"} borderRadius={"md"}
                                    borderWidth={isValid ? "1px" : "2px"} borderStyle={"solid"}>
                                {!idToTitlesQuery.isFetching ?
                                    <>
                                        {_.get(props.form.values, fieldName)?.map((_value: string, i: number) => {
                                            const error = (_.get(props.form.errors, fieldName) as string[])?.[i];
                                            const touched = (_.get(props.form.touched, fieldName) as unknown as FormikTouched<boolean>[])?.[i]
                                            return (
                                                <Box key={i} width={"100%"} px={2} alignItems={"start"}>
                                                    <HStack mt="2">
                                                        <Field name={`${fieldName}.${i}`}
                                                               validate={createRequiredValidator(fieldTitleSingular)}>
                                                            {({field}: FieldProps) => (
                                                                <FormControl isInvalid={error != null && touched}>
                                                                    <Input hidden={true} {...field}
                                                                           id={`${fieldName}.${i}.title`}
                                                                           placeholder={fieldTitleSingular}/>

                                                                    <Text>{idToTitlesQuery.data?.[field.value]}</Text>

                                                                    <FormErrorMessage>{error}</FormErrorMessage>
                                                                </FormControl>
                                                            )}
                                                        </Field>

                                                        <Spacer/>

                                                        {i > 0 &&
                                                        <IconButton borderRadius={"md"} aria-label="Move Up"
                                                                    onClick={() => {
                                                                        props.move(i, i - 1)
                                                                    }}
                                                                    icon={<FiArrowUp/>}/>
                                                        }
                                                        {i < _.get(props.form.values, fieldName).length - 1 &&
                                                        <IconButton borderRadius={"md"} aria-label="Move Down"
                                                                    onClick={() => {
                                                                        props.move(i, i + 1)
                                                                    }}
                                                                    icon={<FiArrowDown/>}/>
                                                        }
                                                        <IconButton colorScheme={"red"}
                                                                    borderRadius={"md"}
                                                                    aria-label="Remove"
                                                                    icon={<FiTrash/>}
                                                                    onClick={() => {
                                                                        props.remove(i)
                                                                    }}/>
                                                    </HStack>

                                                </Box>
                                            );
                                        })}

                                        <Box width={"100%"} px={2} alignItems={"start"}>
                                            <FormControl isDisabled={searchQuery.isFetching}>
                                                <CUIAutoComplete
                                                    label={`Add ${fieldTitleSingular}`}
                                                    placeholder={`Add ${fieldTitleSingular}`}
                                                    hideToggleButton={true}
                                                    disableCreateItem={true}
                                                    labelStyleProps={{display: "none"}}
                                                    selectedItems={[]}
                                                    items={searchQuery.data?.map(e => ({
                                                        value: e.id,
                                                        label: e.title
                                                    })) ?? []}
                                                    onSelectedItemsChange={(changes) => {
                                                        const selectedItem = _.last(changes.selectedItems)
                                                        if (selectedItem) {
                                                            props.push(selectedItem.value)
                                                        }
                                                    }
                                                    }
                                                />
                                            </FormControl>
                                        </Box>
                                    </>
                                    : <Center width={"100%"}><Spinner size="md"/></Center>}
                            </VStack>
                            <FormErrorMessage>{_.get(props.form.errors, `${fieldName}_Array`)}</FormErrorMessage>
                        </FormControl>
                    )
                }}
            </FieldArray>
        </>
    )
}