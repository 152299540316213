import _ from "lodash";

export const getLocalizedFieldValue = (values: any, fieldPath: string, languageCode: string): string | string[] | undefined => {
    const pathItems = fieldPath.split(".")
    const fieldName = _.takeRight(pathItems, 1)
    const parentPathItems = _.dropRight(pathItems, 1)
    const l10n: any[] = _.get(values, _.concat(parentPathItems, ["l10n"]).join("."))
    const localizedItem = _.find(l10n, (v) => (v.l10n_code === languageCode))
    if (localizedItem == null) {
        return
    }
    return _.get(localizedItem, fieldName)
}

export const crowdinLocalizableStringLink = (projectCode: string, fileId: number, langCode:string, stringKey: string): string => {
    return `https://crowdin.com/translate/${projectCode}/${fileId}/en-${langCode}?filter=advanced&#q=${stringKey}`
}