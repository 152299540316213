import {
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    VStack, Center, Link, Spinner, Box, Icon, HStack, IconButton, Flex
} from "@chakra-ui/react";
import {Field, FieldProps, useFormikContext} from "formik";
import * as React from "react";
import _ from "lodash";
import {useDropzone} from "react-dropzone";
import {useCallback, useState} from "react";
import {FiUpload, FiTrash} from "react-icons/fi";

export interface FileUploadResult {
    fileUrl: string
    fileName: string
}

export interface FileUploadService {
    uploadFile(file: File): Promise<FileUploadResult>
}

interface FormFieldFileProps {
    fieldId: string
    fieldTitle: string
    allowedFileTypes?: string[] | undefined
    isRequired?: boolean | undefined
    fileUploadService: FileUploadService
    dropZoneWidth?: string | undefined
    dropZoneHeight?: string | undefined
    fieldValueFunc?: (result: FileUploadResult) => string
}

export const FormFieldFile = (props: FormFieldFileProps) => {
    const {fieldId, fieldTitle, allowedFileTypes, isRequired, fileUploadService, dropZoneWidth, dropZoneHeight} = props
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [hoverIconIsVisible, setHoverIconIsVisible] = useState(false)
    const { setFieldValue } = useFormikContext();
    const fieldValueFunc = props.fieldValueFunc ?? (result => (result.fileUrl))

    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0]
        if (file == null) {
            return
        }
        setIsUploading(true)
        try {
            const result = await fileUploadService.uploadFile(file)
            setFieldValue(fieldId, fieldValueFunc(result))
        } catch (error) {
            console.error(error)
        } finally {
            setIsUploading(false)
        }
    }, [fileUploadService, fieldId, setFieldValue])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        onDrop,
        accept: allowedFileTypes ? allowedFileTypes.join(", ") : undefined
    })

    const validator: ((v: string) => string | undefined) = (v) => {
        if (isRequired === true && !v) {
            return `${fieldTitle} is required`
        }
        return undefined
    }

    return (
        <Field name={fieldId} validate={validator}>
            {({ field, form }: FieldProps) => {
                const isValid = _.get(form.errors, fieldId) == null
                return (
                    <FormControl isInvalid={_.get(form.errors, fieldId) != null && _.get(form.touched, fieldId) as boolean}>
                        <FormLabel htmlFor={fieldId}>{fieldTitle}</FormLabel>
                        <VStack align={"start"} spacing={3} >
                            <Input hidden={true} {...field} id={fieldId} placeholder={fieldTitle} />

                            {!field.value ?
                                <Box w={dropZoneWidth ?? "100%"} title={"Drop a file here or click to select a file"} {...getRootProps()}
                                      position={"relative"}
                                      borderStyle={"solid"} borderRadius={5} borderWidth={isValid ? "1px" : "2px"}
                                      borderColor={isValid ? "gray.200" : "#E53E3E"}
                                      cursor={"pointer"}
                                      onMouseEnter={() => {
                                          setHoverIconIsVisible(true)
                                      }}
                                      onMouseLeave={() => {
                                          setHoverIconIsVisible(false)
                                      }}
                                >
                                    <Box
                                        zIndex={3}
                                        position={"absolute"}
                                        opacity={hoverIconIsVisible || isDragActive ? 0.5 : 0}
                                        backgroundColor={"blue.200"} top={0} bottom={0} left={0} right={0}
                                    >
                                    </Box>

                                    <input {...getInputProps()} />

                                    {!isUploading &&
                                        <Box
                                            zIndex={3}
                                            position={"absolute"}
                                            top={0} bottom={0} left={0} right={0}
                                        >
                                            <Center width={"100%"} height={"100%"}>
                                                <Icon as={FiUpload} color={hoverIconIsVisible ? "white" : "blue.200"}
                                                      w={"30px"}
                                                      h={"30px"}/>
                                            </Center>
                                        </Box>
                                    }

                                    {isUploading &&
                                    <Flex
                                        zIndex={4}
                                        position={"absolute"}
                                        opacity={isUploading ? 1 : 0}
                                        top={0} bottom={0} left={0} right={0}
                                    >
                                        <Center width={"100%"}>
                                            <Spinner size={"xl"} thickness={"4px"} color={"blue.400"}/>
                                        </Center>
                                    </Flex>
                                    }
                                    <Box zIndex={2} align={"center"} width={dropZoneWidth ?? "300px"}
                                         height={dropZoneHeight ?? "100px"} fit={"scale-down"}/>
                                </Box>
                                :
                                <HStack>
                                    <Link href={field.value}>
                                        {field.value}
                                    </Link>

                                    <IconButton
                                        title={"Remove file"}
                                        variant='link'
                                        colorScheme='red'
                                        aria-label='Remove file'
                                        onClick={()=> {
                                            form.setFieldValue(fieldId, undefined)
                                        }}
                                        icon={<FiTrash />}
                                    />
                                </HStack>
                            }
                        </VStack>
                        <FormErrorMessage>{_.get(form.errors, fieldId)}</FormErrorMessage>
                    </FormControl>
                )}}
        </Field>
    )
}